import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "react-intl"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layout"
import SEO from "../seo"
import campaignBuilderGIF from "../../gifs/campaign-builder.gif"

import "./styles.scss"

const url = 'free-postcard-marketing-strategy-report'

const Heading = () => (
  <span>
    Your report is ready!
  </span>
)

const Subheading = () => (
  <span>
    Thank you for downloading our free report. &darr; Scroll down to learn more about our platform.
  </span>
)

const Media = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     campaignBuilderDesktopImage: file(relativePath: { eq: "campaign-builder-desktop.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 800) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <img src={campaignBuilderGIF} alt="Campaign Builder" />
      {/* <Img fluid={data.campaignBuilderDesktopImage.childImageSharp.fluid} /> */}
    </>
  )
}

const whatsIncluded = [
  { text: 'Design template' },
  { text: 'Free proof mailed to you' },
  { text: 'List segmentation' },
  { text: 'Single or batched campaigns' },
  { text: 'Scheduled send dates' },
  { text: 'Any size campaign' },
  { text: 'Friendly service' },
  { text: '... and more!' },
]
const faqs = [
  {
    q: 'What is Early Access?',
    a: 'Early Access is invitation-only access to our platform prior to our public launch. We want to give our early customers a warm welcome and make sure we are providing top-notch service.'
  },
  {
    q: 'Where can I send postcards to?',
    a: 'You can send to any deliverable address inside the US.'
  },
  {
    q: 'Where do I get recipient addresses?',
    a: 'You have the option of uploading your own recipient list or purchasing a mailing list based on your target area.'
  },
  {
    q: 'How many postcards can I send?',
    a: 'Campaigns may range in size from a single postcard to in the thousands.'
  },
  {
    q: 'How are the postcards delivered?',
    a: 'Our postcards are delivered via USPS with your choice of First Class or Standard postage.'
  },
  {
    q: 'How does pricing work?',
    a: 'We are proud to provide small-batch friendly, flat-rate pricing starting at $0.90 per postcard. Price varies on size (4x6, 6x9, 6x11) and postage (First Class, Standard).'
  },
  {
    q: 'Do I need to provide the design?',
    a: 'You have the option of uploading the front and back images of your own design, or you can customize one of our template designs. We also offer professional custom design for your campaign.'
  },
  {
    q: 'Can I get a free proof of my postcard?',
    a: 'Yes! We are happy to send you a free proof of your postcard before you send your campaign.'
  },
  {
    q: 'Can I schedule a future campaign?',
    a: 'Yes! You can choose to send your campaign immediately or schedule it out up to 30 days in advance.'
  },
  {
    q: 'Can I batch my campaign to send over time?',
    a: 'Yes! You can send to your entire recipient list all at once or choose to split the campaign into batches sent up to 14 days apart.'
  }
]

const CaptureCompleted = () => {

  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      iconSimplifyYour: file(relativePath: { eq: "icons/icon-simplify-your-workflow.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconSaveTime: file(relativePath: { eq: "icons/icon-save-time.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconGetClear: file(relativePath: { eq: "icons/icon-get-clear-on-your-return.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconStep1: file(relativePath: { eq: "icons/icon-step-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconStep2: file(relativePath: { eq: "icons/icon-step-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconStep3: file(relativePath: { eq: "icons/icon-step-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <>

      {/* Above the fold */}
      <section className="hero is-light is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              {/* Heading and subheading */}
              <div className="column is-6 landing-caption">
                <h1 className="title is-1 is-light is-semibold is-spaced main-title">
                  <Heading />
                </h1>
                {/* CTA */}
                <p className="buttons mb-5">
                  <a
                    href="https://drive.google.com/file/d/1xWHkxXFJJirFAzCdHtFDWDUTVFZ4Le7X/view"
                    className="button is-primary"
                    target="_blank"
                  >
                    <span className="text has-text-weight-semibold">Download</span>
                  </a>
                </p>
                <h2 className="subtitle is-5 is-light is-thin">
                  <Subheading />
                </h2>
              </div>
              {/* Hero image */}
              <div className="column is-6">
                <figure className="image">
                  <Media />
                  {/* <img src={campaignBuilderGIF} alt="Campaign Builder" /> */}
                  {/* <Img fluid={data.campaignBuilderDesktopImage.childImageSharp.fluid} /> */}
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits with icons */}
      <section id="benefits" className="pt-6">
        <div className="container">
          <div className="column is-6 is-offset-3">
            <div className="divider has-background-primary is-centered"></div>
            <h2 className="title is-light is-semibold has-text-centered is-spaced">
              Gain Leverage 
            </h2>
            <h4 className="subtitle is-6 is-light has-text-centered is-compact">
              Our all-inclusive postcard sending platform <span className="has-text-weight-semibold">accelerates your marketing</span>
            </h4>
          </div>

          <div className="p-6 is-medium">
            <div className="columns has-text-centered">
              {/* Benefit */}
              <div className="column is-4">
                <div className="feature">
                  <figure className="image is-128x128 is-inline-block p-4">
                    <Img fluid={data.iconSimplifyYour.childImageSharp.fluid} />
                  </figure>
                  <h4 className="title is-6 is-tight is-light">Simplify your workflow</h4>
                  <p>
                    Relax knowing all the logistics and handling for your campaign is
                    professionally done for you
                  </p>
                </div>
              </div>
              {/* Benefit */}
              <div className="column is-4">
                <div className="feature">
                  <figure className="image is-128x128 is-inline-block p-4">
                    <Img fluid={data.iconSaveTime.childImageSharp.fluid} />
                  </figure>
                  <h4 className="title is-6 is-tight is-light">Save time and move faster</h4>
                  <p>
                    Send a campaign in just minutes, giving you back your time 
                    and getting your message to market faster
                  </p>
                </div>
              </div>
              {/* Benefit */}
              <div className="column is-4">
                <div className="feature">
                  <figure className="image is-128x128 is-inline-block p-4">
                    <Img fluid={data.iconGetClear.childImageSharp.fluid} />
                  </figure>
                  <h4 className="title is-6 is-tight is-light">Get clear on your return</h4>
                  <p>
                    Eliminate the complexity and back-and-forth to better manage
                    costs and measure ROI
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 3 - 5 step process broken down */}
      <section id="steps" className="pt-6">
        <div className="container">
          <div className="column is-6 is-offset-3">
            <div className="divider has-background-primary is-centered"></div>
            <h2 className="title is-light is-semibold has-text-centered is-spaced">
              3 Easy Steps
            </h2>
            <h4 className="subtitle is-6 is-light has-text-centered is-compact">
              <span className="has-text-weight-semibold">Skip the hassle</span> navigating a designer, a printer and the post office
            </h4>
          </div>

          <div className="p-6 column is-8 is-offset-2">
            <div className="media mb-2">
              <div className="media-left">
                <figure className="image is-32x32 is-inline-block">
                  <Img fluid={data.iconStep1.childImageSharp.fluid} />
                </figure>
              </div>
              <div className="media-content">
                <h3 className="title is-4">Choose your recipients</h3>
                <p className="subtitle is-6">Upload your own list to nurture existing customers
                or create a <span className="has-background-warning px-1">custom mailing list</span> tailored to your target area</p>
              </div>
            </div>
            <div className="media mb-2">
              <div className="media-left">
                <figure className="image is-32x32 is-inline-block">
                  <Img fluid={data.iconStep2.childImageSharp.fluid} />
                </figure>
              </div>
              <div className="media-content">
                <h3 className="title is-4">Choose your design</h3>
                <p className="subtitle is-6">Upload your own design, select from 
                our <span className="has-background-warning px-1">customizable templates</span>, 
                or get a custom professional design</p>
              </div>
            </div>
            <div className="media">
              <div className="media-left">
                <figure className="image is-32x32 is-inline-block">
                  <Img fluid={data.iconStep3.childImageSharp.fluid} />
                </figure>
              </div>
              <div className="media-content">
                <h3 className="title is-4">Send your campaign</h3>
                <p className="subtitle is-6">Control <span className="has-background-warning px-1">scheduling options</span> to 
                send all at once or batched over time, plus the option to start immediately or on your chosen date</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* What's included */}
      <section id="included" className="pt-6">
        <div className="container">
          <div className="column is-6 is-offset-3">
            <div className="divider has-background-primary is-centered"></div>
            <h2 className="title is-light is-semibold has-text-centered is-spaced">
              What's Included
            </h2>
            <h4 className="subtitle is-6 is-light has-text-centered is-compact">
              Everything you need to send postcards from <span className="has-text-weight-semibold">start to finish</span>
            </h4>
          </div>

          <div className="p-6">
            <div className="box is-shadowless is-rounded has-background-light p-5">
              <div className="columns is-multiline is-centered has-text-centered">
                {whatsIncluded.map(({ text }) => (
                  <div className="column is-narrow" key={text}>
                    <div className="box is-shadowless is-rounded has-background-white">
                      <h4 className="title is-6 is-light">
                        <FontAwesomeIcon icon="star" className="has-text-primary mr-2"/>
                        { text }
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA 2 */}
      <section className="pt-6">
        <div className="has-text-centered">
          <a className="button is-primary is-medium is-responsive" href="#demo">
            Start Sending
          </a>
        </div>
      </section>

      {/* FAQ */}
      <section className="pt-6">
        <div className="container">
          <div className="column is-6 is-offset-3">
            <div className="divider has-background-primary is-centered"></div>
            <h2 className="title is-light is-semibold has-text-centered is-spaced">
              FAQ
            </h2>
            {/* <h4 className="subtitle is-6 is-light has-text-centered is-compact">
              text
            </h4> */}
          </div>
          <div className="content-wrapper">
            <div className="columns">
              <div className="column is-5 is-offset-1">
                {/* Accordion */}
                <div className="accordion">
                  {faqs.slice(0, 5).map(({ q, a }, idx) => (
                    <div key={q} className="accordion-option box is-shadowless has-background-light is-rounded">
                      <input
                        type="checkbox"
                        id={`toggle${idx}`}
                        className="accordion-toggle"
                      />
                      <label className="accordion-title has-text-weight-semibold" htmlFor={`toggle${idx}`}>
                        {q}
                      </label>
                      <div className="accordion-content">
                        <p>
                          {a}
                        </p>
                      </div>
                    </div>
                  ))}
                  {faqs.slice(5, 10).map(({ q, a }, idx) => (
                    <div key={q} className="is-hidden-tablet accordion-option box is-shadowless has-background-light is-rounded">
                      <input
                        type="checkbox"
                        id={`toggle${idx+5}`}
                        className="accordion-toggle"
                      />
                      <label className="accordion-title has-text-weight-semibold" htmlFor={`toggle${idx+5}`}>
                        {q}
                      </label>
                      <div className="accordion-content">
                        <p>
                          {a}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* /Accordion */} 
              </div>

              <div className="column is-5 is-hidden-mobile">
                {/* Accordion */}
                <div className="accordion">
                  {faqs.slice(5, 10).map(({ q, a }, idx) => (
                    <div key={q} className="accordion-option box is-shadowless has-background-light is-rounded">
                      <input
                        type="checkbox"
                        id={`toggle${idx+5}-2`}
                        className="accordion-toggle"
                      />
                      <label className="accordion-title has-text-weight-semibold" htmlFor={`toggle${idx+5}-2`}>
                        {q}
                      </label>
                      <div className="accordion-content">
                        <p>
                          {a}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* /Accordion */} 
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Book a demo */}
      <section id="demo" className="has-background-white-bis pt-6 mt-5">
        <div className="column is-6 is-offset-3">
          <div className="divider has-background-primary is-centered"></div>
          <h2 className="title is-light is-semibold has-text-centered is-spaced">
            Book a Demo
          </h2>
          {/* <h4 className="subtitle is-6 is-light has-text-centered is-compact">
            text
          </h4> */}
        </div>
      </section>
      <iframe 
        title="Book Demo"
        src="https://letsmeet.io/customerradiusteam/demo" style={{border:'none', 'minHeight': '700px', width: '1px', 'minWidth': '100%'}} name="booking" scrolling="no" frameBorder="0" marginHeight="0px" marginWidth="0px" width="100%" height="100%" referrerPolicy="unsafe-url" allowFullScreen></iframe>
      
    </>
)}

export default CaptureCompleted