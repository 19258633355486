import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMutation, gql } from "@apollo/client"
import { useIntl } from "react-intl"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layout"
import SEO from "../seo"
import CaptureCompleted from "./capture-completed"

import "./styles.scss"

const SUBMIT_EMAIL_CAPTURE_FORM = gql`
  mutation SubmitEmailCaptureForm($input: EmailCaptureFormInput!) {
    submitEmailCaptureForm(input: $input)
  }
`

const EarlyAccessLandingPage = () => {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

	const [showHelpFirstName, setShowHelpFirstName] = useState(false)
	const [showHelpLastName, setShowHelpLastName] = useState(false)
	const [showHelpEmail, setShowHelpEmail] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [showError, setShowError] = useState(false)

  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      iconSimplifyYour: file(relativePath: { eq: "icons/icon-simplify-your-workflow.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconSaveTime: file(relativePath: { eq: "icons/icon-save-time.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconGetClear: file(relativePath: { eq: "icons/icon-get-clear-on-your-return.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconStep1: file(relativePath: { eq: "icons/icon-step-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconStep2: file(relativePath: { eq: "icons/icon-step-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iconStep3: file(relativePath: { eq: "icons/icon-step-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

const submitForm = (e) => {
  e.preventDefault();

  const validEmail = /\S+@\S+\.\S+/.test(email)
  const validFirstName = firstName.trim() && firstName.length <= 40
  const validLastName = lastName.trim() && lastName.length <= 40
  
  setShowHelpFirstName(!validFirstName);
  setShowHelpLastName(!validLastName);
  setShowHelpEmail(!validEmail);
  setShowSuccess(false);
  setShowError(false);

  if (!validFirstName) return
  if (!validLastName) return
  if (!validEmail) return

  const language = {"en-us":"English", "es-us":"Spanish", "pt-us":"Portuguese"}[intl.locale]

  let source = 'Website'
  let sourceDetail = 'free-postcard-marketing-strategy-report'

  const params = new URLSearchParams(window.location.search);
  if (params.has('gclid')) source = `Google Click ID: ${params.get('gclid')}`
  if (params.has('utm_source') && params.get('utm_source') === 'postcard') source = 'Postcard'

  submitEmailCaptureForm({ variables: { 
    input: { firstName, lastName, email, source, sourceDetail, language }
  }})
}

// submit email capture form
const [submitEmailCaptureForm, {loading: loadingSubmitEmailCaptureForm}] = useMutation(SUBMIT_EMAIL_CAPTURE_FORM, {
  onCompleted: (data) => {
    // console.log(data)
    // TODO: scroll to the top
    
    // setLastName('')
    // setFirstName('')
    // setEmail('')
    setShowHelpFirstName(false)
    setShowHelpLastName(false)
    setShowHelpEmail(false)
    setShowSuccess(true)
    setShowError(false)
  },
  onError: (error) => {
    // TODO: log error
    // TODO: show error count
    // TODO: fix apollo to throw error after too many attempts or service unreachable!
    setShowError(true)
  }
})

  return (
    <Layout showLocaleSwitcher={false} container={false}>
      <SEO title={'Free Postcard Marketing Strategy Report'} />

      {showSuccess ? <CaptureCompleted /> : <>

        {/* Above the fold */}
        <section className="hero is-light is-fullheight-with-navbar">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-vcentered">
                {/* Heading and subheading */}
                <div className="column is-6 landing-caption">
                  <p className="is-semibold has-text-primary">Free</p>
                  <h1 className="title is-1 is-light is-semibold is-spaced main-title">
                    Postcard Marketing Strategy Report
                  </h1>
                  <h2 className="subtitle is-5 is-light is-thin">
                    Learn how to grow your business with postcards!<br /><br />
                    <ul>
                      <li>&rarr; expand your reach</li>
                      <li>&rarr; introduce new products or services</li>
                      <li>&rarr; increase customer loyalty <span className="is-italic">&amp; more</span></li>
                    </ul>
                  </h2>
                </div>
                {/* Hero media */}
                <div className="column is-6">
                <form onSubmit={submitForm}>
                  <h3 className="subtitle is-6 is-semibold">
                    Direct mail delivers amidst the digital fatigue &mdash; don't miss out!
                  </h3>
                  <div className="field">
                    <label className="label has-text-weight-medium" htmlFor="firstName">First name</label>
                    <div className="control">
                      <input id="firstName" className={showHelpFirstName ? 'input is-danger' : 'input'} type="text" name="first_name" maxLength="40" 
                        placeholder="First name"
                        value={firstName}
                        onChange={e=>setFirstName(e.target.value)} />
                        {showHelpFirstName &&
                          <p className="help is-danger">Required</p>
                        }
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-weight-medium" htmlFor="lastName">Last name</label>
                    <div className="control">
                      <input id="lastName" className={showHelpLastName ? 'input is-danger' : 'input'} type="text" name="last_name" maxLength="40" 
                        placeholder="Last name"
                        value={lastName}
                        onChange={e=>setLastName(e.target.value)} />
                        {showHelpLastName &&
                          <p className="help is-danger">Required</p>
                        }
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-weight-medium" htmlFor="email">Email</label>
                    <div className="control">
                      <input id="email" className={showHelpEmail ? 'input is-danger' : 'input'} type="text" name="email" maxLength="50" 
                        placeholder="Email"
                        value={email}
                        onChange={e=>setEmail(e.target.value)} />
                        {showHelpEmail &&
                          <p className="help is-danger">Valid email required</p>
                        }
                    </div>
                  </div>
                  <div className="field mt-6">
                    <div className="control has-text-centered">
                      <button className={loadingSubmitEmailCaptureForm
                        ? "button is-primary is-fullwidth is-loading"
                        : "button is-primary is-fullwidth"} type="submit">
                        DOWNLOAD MY REPORT
                      </button>
                    </div>
                    {showError && <p className="help is-danger">
                      <FontAwesomeIcon icon="exclamation-triangle" className="has-text-danger mr-2"/>
                      Error submitting request
                    </p>}
                    <p className="help has-text-centered has-text-grey-light mt-4">Thank you for sharing your email. We promise to respect your inbox.</p>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>}
    </Layout>
)}

export default EarlyAccessLandingPage